<template>
    <b-modal id="addland-modal" hide-footer dialog-class="width-800">
        <template v-slot:modal-title>
            地块信息 
        </template>
        <div class="d-block">
            <div class="clearfix">
                <div class="float-left text-right mt-1 mr-3 width-100">
                    <span class="text-danger mr-1">*</span><span>土地用途</span>
                </div>
                <div class="float-left">
                    <div class="col" style="width:600px">
                        <div class="row screen">
                            <a href="javascript:;" @click="onUlClick(m)" :class="{ 'active' : para.landUse == m.id }" v-for="m,i in getChildUseLandList" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="text == '耕地'" class="clearfix mt-3">
                <div class="float-left text-right mr-3 width-100">
                    <span>基本农田</span>
                </div>
                <div class="float-left">
                    <b-radio-group v-model="para.isBaseFarmland">
                        <b-radio name="some-radios" value="true">是</b-radio>
                        <b-radio name="some-radios" value="false">否</b-radio>
                        <b-radio name="some-radios" value="">不清楚</b-radio>
                    </b-radio-group>
                </div>
            </div>
            <div v-if="text == '林地'">
                <div class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span>承包性质</span>
                    </div>
                    <div class="float-left">
                      <div class="float-left" v-for="(m,i) in dict.forestcontractortype" :key="i">
                        <b-radio-group v-model="para.contractType" >
                          <b-radio name="some-radios" :value="m.id">{{m.text}}</b-radio>
                        </b-radio-group>
                      </div>
                    </div>
                </div> 
                <div class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span>树龄</span>
                    </div>
                    <div class="float-left">
                        <input v-model="para.treeYears" class="form-control mr-sm-2" maxlength="20" type="text" style="width: 302px;">
                    </div>
                </div>
                <div class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span>树种</span>
                    </div>
                    <div class="float-left">
                        <input v-model="para.treeType" class="form-control mr-sm-2" maxlength="20" type="text" style="width: 302px;">
                    </div>
                </div>
                <div class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span>林种</span>
                    </div>
                    <div class="float-left">
                        <input v-model="para.forestType" class="form-control mr-sm-2" maxlength="20" type="text" style="width: 302px;">
                    </div>
                </div>
            </div>
            <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 width-100">
                    <span>地块编码</span>
                </div>
                <div class="float-left">
                    <input v-model="para.code" class="form-control mr-sm-2" type="text">
                </div>
            </div>
            <div class="clearfix mt-3">
                <div class="float-left text-right mr-3 width-100">
                    <span>四至</span>
                </div>
                <div class="float-left">
                    <div class="clearfix-fix">
                        <input v-model="para.dz" class="float-left form-control mr-sm-2 width-100"  placeholder="东至" maxlength="50" type="text">
                        <input v-model="para.xz" class="float-left form-control mr-sm-2 width-100"  placeholder="西至" maxlength="50" type="text">
                        <input v-model="para.nz" class="float-left form-control mr-sm-2 width-100"  placeholder="南至" maxlength="50" type="text">
                        <input v-model="para.bz" class="float-left form-control mr-sm-2 width-100"  placeholder="北至" maxlength="50" type="text">
                    </div>
                </div>
            </div>
            <div v-if="text == '农房'">
                <div class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span class="text-danger mr-1">*</span><span>宗地面积</span>
                    </div>
                    <div class="float-left">
                        <div class="clearfix" style="height: 38px; line-height: 38px;">
                            <input v-model="para.landAcreage" class="float-left form-control" maxlength="10" type="number"
                                style="width: 150px;">
                                <span class="m-2">平方米（1平方米≈0.0015亩）</span>
                        </div>
                    </div>
                </div>
                <div v-if="tag == '度假山庄' || tag == '农家庭院'" class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span class="text-danger mr-1">*</span><span>建筑面积</span>
                    </div>
                    <div class="float-left">
                        <div class="clearfix" style="height: 38px; line-height: 38px;">
                            <input v-model="para.realEstateAcreage" class="float-left form-control" maxlength="10" type="number"
                                style="width: 150px;">
                                <span class="m-2">平方米（1平方米≈0.0015亩）</span>
                        </div>
                    </div>
                </div>
                <div v-if="tag == '农村宅基地' || tag == '农家庭院'" class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span class="text-danger mr-1">*</span><span>一户一宅</span>
                    </div>
                    <div class="float-left">
                        <b-radio-group v-model="para.oneForOne">
                            <b-radio name="some-radios" value="true">是</b-radio>
                            <b-radio name="some-radios" value="false">否</b-radio>
                        </b-radio-group>
                    </div>
                </div> 
                <div v-if="tag == '农家庭院'">
                    <div class="clearfix mt-3">
                        <div class="float-left text-right mr-3 mt-1 width-100">
                            <span>装修程度</span>
                        </div>
                        <div class="float-left">
                            <div class="col">
                                <div class="row screen">
                                    <a href="javascript:;" @click="para.decorationDegree = m.id" :class="{ 'active' : para.decorationDegree == m.id }" v-for="m,i in dict.decorationDegree" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix mt-3">
                        <div class="float-left text-right mr-3 mt-1 width-100">
                            <span>结构</span>
                        </div>
                        <div class="float-left">
                            <div class="col">
                                <div class="row screen">
                                    <a href="javascript:;" @click="para.structure = m.id" :class="{ 'active' : para.structure == m.id }" v-for="m,i in dict.structure" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix mt-3">
                        <div class="float-left text-right mr-3 width-100">
                            <span class="text-danger mr-1">*</span><span>总层数</span>
                        </div>
                        <div class="float-left">
                            <input v-model="para.totalPlies"  class="form-control" maxlength="10" type="number">
                        </div>
                    </div>
                </div>
                
                <div v-if="tag == '度假山庄' || tag == '农家庭院'" class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span>竣工时间</span>
                    </div>
                    <div class="float-left">
                        <date-picker v-model="para.finishDate" :editable="false" :show-second="false" input-class="form-control width-220" type="data" style="width:220px"></date-picker>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="clearfix mt-3">
                    <div class="float-left text-right mr-3 width-100">
                        <span class="text-danger mr-1">*</span><span>土地面积</span>
                    </div>
                    <div class="float-left">
                        <div class="clearfix" style="height: 38px; line-height: 38px;">
                            <input v-model="para.acreageM" class="float-left form-control" maxlength="10" type="number"
                                style="width: 150px;">
                                <span class="m-2">亩 （1亩≈666.7平米）</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <b-button class="mt-3" @click="addLand" block variant="success" style="margin-left: 116px;width: 229px;">添加地块信息</b-button>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
export default {
    components: {
        datePicker
    },
    props: {
        classId: {
            type: String,
            default: '',
        }
    },
    computed: {
        ...mapGetters(["dict","user"]),
        getChildUseLandList() {
            if(this.dict.landUseType.length > 0) {
                let c = this.dict.landUseType.find(x=> x.id == this.classId);
                if (c)  {
                    return c.children;
                } 
            }
            return []
        },
        text() {
             if(this.dict.landUseType.length > 0) {
                let c = this.dict.landUseType.find(x=> x.id == this.classId);
                if (c)  {
                    return c.text;
                } 
            }
            return ''
        }
    },
    data() {
        return {
            tag: '',
            index: -1,
            para: {
                classId: '', //用途大类
                acreageM: '', //实测面积
                isBaseFarmland: '', //是否基本农田
                landUse: '', //用途小类
                code: '', //地块编码
                dz: '', //东至
                xz: '', //西至
                nz: '', //南至
                bz: '', //北至

                //林地
                contractType: '', //承包性质
                forestType: '', //林种
                treeType: '',//树种
                treeYears: '', //树龄

                //农房
                decorationDegree: '', //装修程度
                finishDate: '', //竣工日期
                landAcreage: '', //宗地面积
                oneForOne: '', //一宅一户
                realEstateAcreage: '', //建筑面积
                realEstateAddress: '', //不动产地址
                structure: '', //结构
                totalPlies: '', //总层数
            }
        }
    },
    methods: {
        showModal(index,m) {
            if(index > 0) {
                this.index = index;
                this.para = m;
            }
            this.$bvModal.show('addland-modal')
        },
        onUlClick(m) {
            this.para.classId = m.parentId;
            this.para.landUse = m.id;
            this.tag = m.text;
        },
        verify() {
            let temp = true;
            if(this.para.landUse == '') {
                temp = false;
            } 

            if(this.text != '农房') {
                if(this.para.acreageM.trim() == '') {
                    temp = false;
                } 
            } else {
                if(this.para.landAcreage.trim() == '') {
                    temp = false;
                } 
                
                if(this.tag == '农村在基地') {
                    if(this.para.oneForOne.trim() == '') {
                        temp = false;
                    }
                } 
                if(this.tag == '农家庭院') {
                    if(this.para.oneForOne.trim() == '' || this.para.realEstateAcreage.trim() == '' || this.para.totalPlies.trim() == '') {
                        temp = false;
                    }
                } 
                if(this.tag == '度假山庄') {
                    if(this.para.realEstateAcreage.trim() == '') {
                        temp = false;
                    }
                } 
            }

            if(!temp) {
                this.$model.toast('带*符号为必填项，请认真填写必填项！',2000)
            }
            return temp;
            
        },
        addLand() {
            if(this.verify()) {
                this.$emit("change",this.index,this.para);
                this.$bvModal.hide('addland-modal');
                Object.assign(this.$data, this.$options.data())
            }
        }
    }
}
</script>
