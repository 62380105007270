<template>
	<div>
		<search-header></search-header>
		<div class="container">
			<div class="row mt-3">
				<b-alert class="w-100" show variant="warning">
					发布土地或农机具信息，需要实名认证或企业认证，如果您还没有认证请点击 "<router-link to="/user/account/certification/personal" target="_blank">实名认证</router-link>" , "<router-link to="/user/account/certification/company" target="_blank">企业认证</router-link>"
				</b-alert>
			</div>
			<div class="row rounded mb-3">
				<b-card class="w-100" no-body>
					<b-tabs pills card>
						<b-tab v-for="(m,i) in getUseLand" :key="i" @click="onClassClick(m)" :title="m.text"  :active="i == 0">
							<div class="d-flex">
								<div class="text-right mt-1 mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>土地用途</span>
								</div>
								<div>
									<div class="col" style="width:970px">
										<div class="row screen">
											<a href="javascript:;" @click="onUlClick(m)" :class="{ 'active' : gyPara[0].landUse == m.id }" v-for="(m,i) in getChildUseLandList" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
										</div>
									</div>
								</div>
							</div>
							<div v-if="m.text == '耕地'" class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>基本农田</span>
								</div>
								<div class="float-left">
									<b-radio-group v-model="gyPara[0].isBaseFarmland">
										<b-radio name="some-radios" value="true">是</b-radio>
										<b-radio name="some-radios" value="false">否</b-radio>
										<b-radio name="some-radios" value="">不清楚</b-radio>
									</b-radio-group>
								</div>
							</div>
							<div v-if="m.text == '林地'">
                                <div class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 width-100">
                                        <span>承包性质</span>
                                    </div>
                                    <div class="float-left" v-for="(m) in dict.forestcontractortype" :key="m.id">
                                        <b-radio-group v-model="gyPara[0].contractType" >
                                            <b-radio name="some-radios" :value="m.id">{{m.text}}</b-radio>
                                        </b-radio-group>
                                    </div>
                                </div> 
                                <div class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 width-100">
                                        <span>树龄</span>
                                    </div>
                                    <div class="float-left">
                                        <input v-model="gyPara[0].treeYears" class="form-control mr-sm-2" maxlength="20" type="text" style="width: 302px;">
                                    </div>
                                </div>
                                 <div class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 width-100">
                                        <span>树种</span>
                                    </div>
                                    <div class="float-left">
                                        <input v-model="gyPara[0].treeType" class="form-control mr-sm-2" maxlength="20" type="text" style="width: 302px;">
                                    </div>
                                </div>
                                 <div class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 width-100">
                                        <span>林种</span>
                                    </div>
                                    <div class="float-left">
                                        <input v-model="gyPara[0].forestType" class="form-control mr-sm-2" maxlength="20" type="text" style="width: 302px;">
                                    </div>
                                </div>
                            </div>
							<div class="clearfix  mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>所在地区</span>
								</div>
								<div class="float-left areabox box">
									<input @click.stop="isAreaShow = !isAreaShow" :value="areaValue"  class="form-control" type="text" placeholder="城市选择" readonly="readonly">
									<em>
										<b-icon icon="chevron-down"></b-icon>
									</em>
									<city v-model="isAreaShow" @onChoose="onChooseArea"></city>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>详细地址</span>
								</div>
								<div class="float-left">
									<input v-model="para.address" class="form-control mr-sm-2" maxlength="20" type="text" style="width: 302px;">
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>地理位置</span>
								</div>
								<div class="float-left">
									<div class="clearfix-fix">
										<input class="float-left form-control mr-sm-2 w-50"  v-model="para.location" maxlength="20" type="text">
										<b-button @click="onMapLocation()" class="float-left" variant="success" style="width: 120px;">标记坐标</b-button>
									</div>
								</div>
							</div>
							<baidu-map v-if="isMapShow" @click="onMapClick" :center="mapKeyword" :zoom="15" :scroll-wheel-zoom="true">
								<bm-view class="map"></bm-view>
								<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
								<bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_SATELLITE_MAP']" anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-map-type>
								<bm-marker :position="ag"> </bm-marker>
								<bm-control>
									<b-input v-model="mapKeyword" placeholder="请输入地名关键字"  style="position: absolute;top:10px;left:10px;width:240px"></b-input> <!-- 这里指代一个自定义搜索框组件 -->
								</bm-control>
							</baidu-map>

							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>地块编码</span>
								</div>
								<div class="float-left">
									<input v-model="gyPara[0].code" class="form-control mr-sm-2" type="text" style="width: 302px;">
								</div>
							</div>

							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>四至</span>
								</div>
								<div class="float-left">
									<div class="clearfix-fix">
										<input v-model="gyPara[0].dz" class="float-left form-control mr-sm-2 width-100"  placeholder="东至" maxlength="50" type="text">
                                        <input v-model="gyPara[0].xz" class="float-left form-control mr-sm-2 width-100"  placeholder="西至" maxlength="50" type="text">
                                        <input v-model="gyPara[0].nz" class="float-left form-control mr-sm-2 width-100"  placeholder="南至" maxlength="50" type="text">
                                        <input v-model="gyPara[0].bz" class="float-left form-control mr-sm-2 width-100"  placeholder="北至" maxlength="50" type="text">
									</div>
								</div>
							</div>

							<div v-if="m.text == '农房'">
                                <div class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 width-100">
                                        <span class="text-danger mr-1">*</span><span>宗地面积</span>
                                    </div>
                                    <div class="float-left">
                                        <div class="clearfix" style="height: 38px; line-height: 38px;">
                                            <input v-model="gyPara[0].landAcreage" @change="laChange" class="float-left form-control" maxlength="10" type="number"
                                                style="width: 150px;">
                                                <span class="m-2">平方米（1平方米≈0.0015亩）</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tag[2] == '度假山庄' || tag[2] == '农家庭院'" class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 width-100">
                                        <span class="text-danger mr-1">*</span><span>建筑面积</span>
                                    </div>
                                    <div class="float-left">
                                        <div class="clearfix" style="height: 38px; line-height: 38px;">
                                            <input v-model="gyPara[0].realEstateAcreage" class="float-left form-control" maxlength="10" type="number"
                                                style="width: 150px;">
                                                <span class="m-2">平方米（1平方米≈0.0015亩）</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tag[2] == '农村宅基地' || tag[2] == '农家庭院'" class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 width-100">
                                        <span class="text-danger mr-1">*</span><span>一户一宅</span>
                                    </div>
                                    <div class="float-left">
                                        <b-radio-group v-model="gyPara[0].oneForOne">
                                            <b-radio name="some-radios" value="true">是</b-radio>
                                            <b-radio name="some-radios" value="false">否</b-radio>
                                        </b-radio-group>
                                    </div>
							                  </div>
                                <div class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 mt-1 width-100">
                                        <span class="text-danger mr-1">*</span><span>流转类型</span>
                                    </div>
                                    <div class="float-left">
                                        <div class="col">
                                            <div class="row screen">
                                              <a href="javascript:;" @click="onFtClick(m)" :class="{ 'active' : para.flowType == m.id }" v-for="(m,i) in dict.circulationType.filter(x=> ['出租','转让','合作'].includes(x.text))" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tag[2] == '农家庭院'">
                                    <div class="clearfix mt-3">
										<div class="float-left text-right mr-3 mt-1 width-100">
											<span>装修程度</span>
										</div>
										<div class="float-left">
											<div class="col">
												<div class="row screen">
													<a href="javascript:;" @click="gyPara[0].decorationDegree = m.id" :class="{ 'active' : gyPara[0].decorationDegree == m.id }" v-for="(m,i) in dict.decorationDegree" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
												</div>
											</div>
										</div>
									</div>
									<div class="clearfix mt-3">
										<div class="float-left text-right mr-3 mt-1 width-100">
											<span>结构</span>
										</div>
										<div class="float-left">
											<div class="col">
												<div class="row screen">
													<a href="javascript:;" @click="gyPara[0].structure = m.id" :class="{ 'active' : gyPara[0].structure == m.id }" v-for="(m,i) in dict.structure" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
												</div>
											</div>
										</div>
									</div>
									<div class="clearfix mt-3">
										<div class="float-left text-right mr-3 width-100">
											<span class="text-danger mr-1">*</span><span>总层数</span>
										</div>
										<div class="float-left">
											<input v-model="gyPara[0].totalPlies"  class="form-control" maxlength="10" type="number">
										</div>
									</div>
								</div>

                                <div v-if="tag[2] == '度假山庄' || tag[2] == '农家庭院'" class="clearfix mt-3">
                                    <div class="float-left text-right mr-3 width-100">
                                        <span>竣工时间</span>
                                    </div>
                                    <div class="float-left">
                                       <date-picker v-model="gyPara[0].finishDate" :editable="false" :show-second="false" input-class="form-control width-220" type="data" style="width:220px"></date-picker>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="clearfix mt-3">
									<div class="float-left text-right mr-3 width-100">
										<span class="text-danger mr-1">*</span><span>土地面积</span>
									</div>
									<div class="float-left">
										<div class="clearfix" style="height: 38px; line-height: 38px;">
											<input v-model="para.measureArea" @change="maChange" class="float-left form-control" maxlength="10" type="number"
												style="width: 150px;">
												<span class="m-2">{{currentUtil.acreage}} {{ currentUtil.acreage == '亩' ? '（1亩≈666.7平米）' : '（1平方米≈0.0015亩）'}}</span>
										</div>
									</div>
								</div>
								<div v-if="dict.circulationType.length > 0" class="clearfix mt-3">
									<div class="float-left text-right mr-3 mt-1 width-100">
										<span class="text-danger mr-1">*</span><span>流转类型</span>
									</div>
									<div class="float-left">
										<div class="col">
											<div class="row screen">
												<a href="javascript:;" @click="onFtClick(m)" :class="{ 'active' : para.flowType == m.id }" v-for="(m,i) in dict.circulationType" :key="i" class="mr-3 px-2 py-1">{{m.text}}</a>
											</div>
										</div>
									</div>
								</div>
                            </div>
							
							<div v-if="m.text == '耕地'" class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>单价</span>
								</div>
								<div class="float-left">
									<div class="clearfix" style="height: 38px; line-height: 38px;">
										<input v-model="para.unitPrice" class="float-left form-control" maxlength="10" type="number"
											style="width: 160px;">
										<span class="m-2">{{currentUtil.unitPrice}}</span>
									</div>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>总价</span>
								</div>
								<div class="float-left">
									<div class="clearfix" style="height: 38px; line-height: 38px;">
										<input v-model="para.price" class="float-left form-control" maxlength="10" type="number"
											style="width: 160px;">
										<span class="m-2">{{currentUtil.price}}</span>
									</div>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>流转年限</span>
								</div>
								<div class="float-left">
									<div class="clearfix" style="height: 38px; line-height: 38px;">
										<input v-model="para.years" class="float-left form-control" maxlength="10" type="number"
											style="width: 160px;">
										<span class="m-2">年</span>
									</div>
								</div>
							</div>

							<div class="d-flex mt-3" v-if="m.text != '农房'">
								<div class="text-right mr-3 width-100">
									<span >适合经营</span>
								</div>
								<div v-if="m.text=='耕地' || m.text=='园地' || m.text=='草地'||m.text=='养殖用地' ">
									<div class="d-flex flex-wrap p-1" :class="{'show-more': isReadMore == false}">
										<!-- <b-form-group>
											<b-form-checkbox-group v-model="para.serviceTag" name="flavour-2">
												<div v-for="m in dict.landTags.filter(x=>x.level != 1 && x.id!=2  && x.id!=6)" :key="m.id">
													<b-form-checkbox class="float-left"  :value="m.id">{{m.text}}</b-form-checkbox>
												</div>
											</b-form-checkbox-group>
										</b-form-group> -->
										<div v-for="m in dict.landTags.filter(x=>x.level != 1)" :key="m.id">
											<b-button @click="onChooseTags(m.id)" :variant="para.serviceTag.includes(m.id) ? 'success' : 'secondary'" class="width-80 mr-2 mb-2" size="sm" pill>{{m.text}}</b-button>
										</div>
									</div>
									<div class="text-center">
										<span v-show="isReadMore == false" @click="isReadMore = true" class="text-primary f-14 cursor-hand">
											展开全部 <b-icon icon="chevron-down"></b-icon>
										</span>
									</div>
								</div>

								<div class="float-left" v-if="m.text=='林地'" style="width:970px">
									<div class="clearfix-fix" >
										<b-form-group>
											<b-form-checkbox-group  v-model="para.serviceTag" name="flavour-2">
												<div v-for="m in dict.landTags.filter(x=>x.level != 1  &&x.parentId==6)" :key="m.id">
													<b-form-checkbox  class="float-left" :value="m.id">{{m.text}}</b-form-checkbox>
												</div>
											</b-form-checkbox-group>
										</b-form-group>
									</div>
								</div>

								<div class="float-left" v-if="m.text=='其他土地'" style="width:970px">
									<div class="clearfix-fix" >
										<b-form-group>
											<b-form-checkbox-group v-model="para.serviceTag" name="flavour-2">
												<div v-for="m in dict.landTags.filter(x=>x.level != 1  &&x.parentId==2)" :key="m.id">
													<b-form-checkbox  class="float-left"  :value="m.id">{{m.text}}</b-form-checkbox>
												</div>
											</b-form-checkbox-group>
										</b-form-group>
									</div>
								</div>
							</div>

							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>上传图片</span>
								</div>
								<div class="float-left">
									<div class="f-14 text-primary mb-1">
										单张最大6M,最多上传6张，支持：JPG/JPEG/GIF/PNG 格式
									</div>
									<van-uploader v-model="fileList[0]" :multiple="true" :preview-full-image="false" :after-read="afterReadLand" :before-delete="beforeDeteleLand" :max-count="6" :max-size="6 * 1024 * 1024"></van-uploader>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>上传视频</span>
								</div>
								<div class="float-left">
									<div class="f-14 text-primary mb-1">
										据平台统计，有视频的土地咨询量将会提高50%
									</div>
									<van-uploader v-model="fileList[1]" accept="video/*" :preview-full-image="false" :max-count="1" :after-read="afterReadVideo" :before-delete="beforeDeteleVideo"></van-uploader>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>上传权证</span>
								</div>
								<div class="float-left">
									<div class="f-14 text-primary mb-1">
										产权凭证不会对外展示，仅供平台审核土地时查看，有产权凭证的土地，平台将优先推荐给客户
									</div>
									<van-uploader v-model="fileList[2]" :max-count="1" :preview-full-image="false" :max-size="6 * 1024 * 1024"  :after-read="afterReadWarrant" :before-delete="beforeDeteleWarrant"></van-uploader>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>信息标题</span>
								</div>
								<div class="float-left">
									<input v-model="para.title" class="float-left form-control mb-2 width-500" maxlength="50" type="text">
									<div class="clearfix f-14 text-primary">
										如有特殊要求，请在默认标题追加内容，这样被搜索到、展示的几率更高！
									</div>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span>土地详情</span>
								</div>
								<div class="float-left">
									<textarea v-model="para.content" placeholder="可描述土地的周边环境、交通状况、土地状况、交易细则描述等信息。
例如：
1、水源充足，有2-3米宽沟渠，水库水源；
2、交通方便，距城区25公里，距国道7公里，全程5米宽水泥路直通山边，离村庄有一定距离，方便管理；
3、水泥路直达，方便机械化作业；
4、肥沃黄土，土质好，可种植，可养殖；
5、土地租金160元/亩/年，押一付三，租期30年" class="form-control mr-sm-2"
										style="width: 600px;height: 210px;"></textarea>
									<a @click="isMsShow = true" href="javascript:;" class="clearfix f-14 text-theme mt-2 d-flex align-items-center">
										<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-question-octagon" viewBox="0 0 16 16">
											<path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
											<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
										</svg>
										<span class="ml-1"> 如何填写描述</span>
									</a>	
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>联系人</span>
								</div>
								<div class="float-left">
									<input v-model="para.contacts" class="form-control mr-sm-2" maxlength="10" type="text">
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>联系电话</span>
								</div>
								<div class="float-left">
									<input v-model="para.contactNumber" class="form-control mr-sm-2" maxlength="11" type="text">
								</div>
							</div>
							<div v-if="gyPara.length > 1">
								<div v-for="(m,i) in gyPara" :key="i">
									<div v-if="i > 0"  class="border rounded mt-3 mx-4 p-3">
										<div class="d-flex justify-content-between">
											<div class="h5">地块{{i+1}}</div>
											<div>
												<a href="javascript:;" @click="onEdit(i,m)">
													<b-icon icon="pencil"></b-icon>  
												</a>
												<a @click="onDelete(i)" href="javascript:;" class="ml-2">
													<b-icon icon="trash"></b-icon>  
												</a>
											</div>
										</div>
										<div class="d-flex flex-wrap">
											<div class="container p-0">
												<div class="row p-0">
													<div v-if="m.landUse" class="col-3 py-2 d-flex">
														<div class="text-secondary">土地用途：</div>
														<div class="text-truncate width-120">{{getUseLandValue(m.landUse)}}</div>
													</div>
													<div v-if="getUseLandValue(m.landUse).includes('耕地')" class="col-3 py-2 d-flex">
														<div class="text-secondary">基本农田：</div>
														<div class="text-truncate width-120">{{m.isBaseFarmland == 'true' ? '是' : (m.isBaseFarmland == 'false' ? '否' : '不清楚') }}</div>
													</div>
													<div v-if="m.contractType" class="col-3 py-2 d-flex">
														<div class="text-secondary">承包性质：</div>
														<div class="text-truncate width-120">{{getcontractTypetValue(m.contractType)}}</div>
													</div>
													<div v-if="m.acreageM" class="col-3 py-2 d-flex">
														<div class="text-secondary">土地面积：</div>
														<div class="text-truncate width-120">{{m.acreageM}}亩</div>
													</div>
													<div v-if="m.code" class="col-3 py-2 d-flex">
														<div class="text-secondary">地块编码：</div>
														<div class="text-truncate width-120">{{m.code}}</div>
													</div>
													<div v-if="m.oneForOne" class="col-3 py-2 d-flex">
														<div class="text-secondary">一户一宅：</div>
														<div class="text-truncate width-120">{{m.oneForOne== 'true' ? '是' : '否'}}</div>
													</div>
													<div v-if="m.landAcreage" class="col-3 py-2 d-flex">
														<div class="text-secondary">宗地面积：</div>
														<div class="text-truncate width-120">{{m.landAcreage}}平方米</div>
													</div>
													<div v-if="m.realEstateAcreage" class="col-3 py-2 d-flex">
														<div class="text-secondary">建筑面积：</div>
														<div class="text-truncate width-120">{{m.realEstateAcreage}}平方米</div>
													</div>
													<div v-if="m.decorationDegree" class="col-3 py-2 d-flex">
														<div class="text-secondary">装修程度：</div>
														<div class="text-truncate width-120">{{getdecorationDegreeTypetValue(m.decorationDegree)}}</div>
													</div>
													<div v-if="m.finishDate" class="col-3 py-2 d-flex">
														<div class="text-secondary">竣工时间：</div>
														<div class="text-truncate width-120">{{DateFormat(m.finishDate)}}</div>
													</div>
													<div v-if="m.totalPlies" class="col-3 py-2 d-flex">
														<div class="text-secondary">总层数：</div>
														<div class="text-truncate width-120">{{m.totalPlies}}</div>
													</div>
													<div v-if="m.structure" class="col-3 py-2 d-flex">
														<div class="text-secondary">结构：</div>
														<div class="text-truncate width-120">{{getstructureTypetValue(m.structure)}}</div>
													</div>
													<div v-if="m.treeYears" class="col-3 py-2 d-flex">
														<div class="text-secondary">树龄：</div>
														<div class="text-truncate width-120">{{m.treeYears}}</div>
													</div>
													<div v-if="m.treeType" class="col-3 py-2 d-flex">
														<div class="text-secondary">树种：</div>
														<div class="text-truncate width-120">{{m.treeType}}</div>
													</div>
													<div v-if="m.forestType" class="col-3 py-2 d-flex">
														<div class="text-secondary">林种：</div>
														<div class="text-truncate width-120">{{m.forestType}}</div>
													</div>

													<div v-if="m.dz" class="col-3 py-2 d-flex">
														<div class="text-secondary">东至：</div>
														<div class="text-truncate width-120">{{m.dz}}</div>
													</div>
													<div v-if="m.xz" class="col-3 py-2 d-flex">
														<div class="text-secondary">西至：</div>
														<div class="text-truncate width-120">{{m.xz}}</div>
													</div>
													<div v-if="m.nz" class="col-3 py-2 d-flex">
														<div class="text-secondary">南至：</div>
														<div class="text-truncate width-120">{{m.nz}}</div>
													</div>
													<div v-if="m.bz" class="col-3 py-2 d-flex">
														<div class="text-secondary">北至：</div>
														<div class="text-truncate width-120">{{m.bz}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="clearfix mt-3" style="margin-left: 116px;">
								<div class='signup-details_form__item'>
									<div class="d-flex flex-row">
										<div class="d-flex text-sm">
											<b-form-checkbox v-model="isRead"
												value="true" unchecked-value="false">
												我已阅读并同意
											</b-form-checkbox>
										</div>
										<div class="d-flex ml-1">
											<strong class="text-sm-left f-14" style="margin-top: 2px;">
												<a @click="isAgreementShow = true" href='javascript:;' class="text-theme">《土地流转委托协议》</a>
											</strong>
										</div>
									</div>
								</div>
								<div class="d-flex align-items-center mt-2">
									<b-button @click="addLand" variant="success"
									style="width: 229px;">立即发布</b-button>
									<div class="p-3">
										<a @click="$refs.landmodal.showModal(0,null)"  href="javascript:;">继续添加打包地块信息</a>
									</div>
								</div>
							</div>
						</b-tab>
					</b-tabs>
				</b-card>
			</div>
		</div>
		<b-modal title="如何填写描述" v-model="isMsShow" scrollable hide-footer>
			<div class="miaoshu-txt"><p>可描述土地的周边环境、交通状况、土地状况、交易细则描述等信息。</p><p>例如：</p><p>1、水源充足，有2-3米宽沟渠，水库水源；</p><p>2、交通方便，距城区25公里，距国道7公里，全程5米宽水泥路直通山边，离村庄有一定距离，方便管理；</p><p>3、水泥路直达，方便机械化作业；</p><p>4、肥沃黄土，土质好，可种植，可养殖；</p><p>5、土地租金160元/亩/年，押一付三，租期30年</p></div>
		</b-modal>
		<b-modal title="土地流转委托协议" dialog-class="width-1200" v-model="isAgreementShow"  footer-class="justify-content-center" hide-header-close  ok-title="我知道了" ok-variant="success" modal-cancel="true">
			<div class="cke_editable">
				<p style="text-align:center"><strong>土地流转</strong><strong>委托</strong><strong>服务协议</strong></p>

				<p><strong>特别提示：</strong></p>
				
				<p><strong>为使用</strong><strong>农村综合产权交易服务平台</strong><strong>服务，您应当阅读并遵守《</strong><strong>土地流转委托服务协议</strong><strong>》。本协议是</strong><strong>找</strong><strong>客</strong><strong>用户与</strong><strong>农村综合产权交易服务平台</strong><strong>之间的法律协议，是</strong><strong>找</strong><strong>客</strong><strong>用户注册</strong><strong>农村综合产权交易服务平台</strong><strong>账号和/或使用</strong><strong>农村综合产权交易服务平台</strong><strong>服务时使用的通用条款。请您务必审慎阅读、充分理解各条款内容，特别是关于用户信息的使用范围、免责条款、管辖与法律适用条款。用户信息的使用范围、免责条款可能以黑体加粗或/和加下划线的形式提示您重点注意。您不应当以</strong><strong>农村综合产权交易服务平台</strong><strong>未对本协议以合理方式提醒用户注意或未根据用户要求尽到说明义务为理由而声称或要求法院或其它任何第三方确认相关条款非法或无效。除非您已阅读并接受本协议所有条款，否则您无权使用</strong><strong>农村综合产权交易服务平台</strong><strong>提供的服务。您使用</strong><strong>农村综合产权交易服务平台</strong><strong>提供的服务即视为您已阅读并同意上述协议的约束。</strong></p>
				
				<p><strong>农村综合产权交易服务平台</strong><strong>可能会根据需要不时修订或者更新本协议及其相关补充和附件、规则、单项服务规则等文件，会在首页显著位置公开征求意见，确保您能够及时充分表达意见。修改内容至少在实施前七日予以公示。您如果不接受修改内容，要求退出</strong><strong>农村综合产权交易服务平台</strong><strong>的，</strong><strong>农村综合产权交易服务平台</strong><strong>将无法继续向您提供服务。但用户服务协议修改前，双方发生的争议仍然使用修改前的用户服务协议进行解决。</strong></p>
				
				<p><strong>一、定义</strong></p>
				
				<p>1、农村综合产权交易服务平台：是指湖南土流信息有限公司旗下运营之农村综合产权交易服务平台站（www.tuliu.com)、农村综合产权交易服务平台无线站点（m.tuliu.com）、农村综合产权交易服务平台移动应用软件（APP）。</p>
				
				<p>2、用户/找客用户，包含注册用户和非注册用户，以下亦称为“您”。注册用户是指通过农村综合产权交易服务平台完成全部注册程序后，使用农村综合产权交易服务平台服务或农村综合产权交易服务平台站资料寻找合适客源进行土地流转交易的用户。非注册用户是指未进行注册、直接登录农村综合产权交易服务平台或通过其他网站进入农村综合产权交易服务平台直接或间接地使用农村综合产权交易服务平台服务或农村综合产权交易服务平台站资料的用户。</p>
				
				<p><strong>二、注册</strong></p>
				
				<p>1、在进行注册过程中只要您点击注册页面的"立即注册"按钮并按照农村综合产权交易服务平台注册程序成功注册为用户，即表示您同意并签署了本服务协议，本服务协议即在您和农村综合产权交易服务平台之间产生法律效力。请您务必在注册之前认真阅读全部服务协议内容，如有任何疑问，可向本网站咨询。</p>
				
				<p>2、用户一经注册农村综合产权交易服务平台帐号，除非子频道要求单独开通权限，用户有权利用该帐号使用农村综合产权交易服务平台各个频道的单项服务，当用户使用农村综合产权交易服务平台各单项服务时，用户的使用行为视为其对该单项服务的服务条款以及农村综合产权交易服务平台在该单项服务中发出的各类公告的同意。</p>
				
				<p>3、用户注册成功后，农村综合产权交易服务平台将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管，该账号所有权属于农村综合产权交易服务平台，该账号的使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。除农村综合产权交易服务平台过错外，用户就其账户及密码项下的之一切活动负全部责任，而无论该活动是否对用户自身或第三方造成任何损害。</p>
				
				<p>4、您同意并认可，为满足您的找客需求促成您达成交易，农村综合产权交易服务平台可采用包括但不限于平台展示、信息发布等多种方式向外界透露您的注册信息。如您发现您的帐号遭他人非法使用，应立即通知农村综合产权交易服务平台，您理解农村综合产权交易服务平台对用户的任何请求采取行动均需要合理时间，因此，除农村综合产权交易服务平台存在重大过错外，农村综合产权交易服务平台对在采取行动前已产生的后果不承担任何责任。互联网上不排除因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，此类情况与农村综合产权交易服务平台无关。</p>
				
				<p><strong>三、地源信息的发布</strong></p>
				
				<p><strong>1</strong><strong>、用户应提供详尽及准确的个人资料</strong><strong>、</strong><strong>地源信息</strong><strong>及所需客源信息</strong><strong>（统称为“注册信息”）</strong><strong>，并当用户信息</strong><strong>和需求</strong><strong>发生变更时应不断更新</strong><strong>注册信息</strong><strong>。如果因注册信息不真实或更新不及时而引发的相关问题，</strong><strong>农村综合产权交易服务平台</strong><strong>不负任何责任，由用户承担。</strong></p>
				
				<p><strong>2、您保证您提交的土地信息真实、准确、有效，不存在任何权属瑕疵，也不存在或潜在未向农村综合产权交易服务平台说明的权属争议和纠纷,并保证您有权单独或已取得合法授权处置您提供的土地，保证您向农村综合产权交易服务平台提供的全部资料的真实性、有效性、准确性。如您提交不实信息或隐瞒产权瑕疵等相关争议信息，且由此产生的相关责任及后果均由您承担。</strong></p>
				
				<p><strong>3、您保证土地流转交易相关信息（包括但不限于流转交易价格、年限、用途等）经发布后不可随意更改。</strong></p>
				
				<p><strong>四、委托内容</strong></p>
				
				<p><strong>1、您在农村综合产权交易服务平台提交</strong><strong>您自有或有权处置的</strong><strong>地源信息，并同意本协议，视为您独家委托农村综合产权交易服务平台为您</strong><strong>提交的</strong><strong>地源</strong><strong>提供</strong><strong>土地流转居间服务（您不得再另行委托任何其他第三方）</strong><strong>，委托期限至您</strong><strong>与客源签署土地流转协议之日止</strong><strong>。</strong></p>
				
				<p><strong>2、依据您注册时提交的地源信息，农村综合产权交易服务平台可视具体情况采取信息公示、发布等多种形式向平台内用户、代理商展示您的联系方式及地源信息。</strong></p>
				
				<p><strong>3、</strong><strong>农村综合产权交易服务平台特别提醒您在整个土地流转交易过程中，应尽到审慎的义务，对</strong><strong>土地流转交易的相关方</strong><strong>进行</strong><strong>有关资质的</strong><strong>实质审查。</strong></p>
				
				<p><strong>五</strong><strong>、免责声明</strong></p>
				
				<p><strong>1、因以下情况造成网络服务在合理时间内的中断，农村综合产权交易服务平台无需为此承担任何责任：（1）农村综合产权交易服务平台需要定期或不定期地对提供网络服务的系统或相关的设备进行检修或者维护，农村综合产权交易服务平台保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；（3）用户的电脑软硬件和通信线路、供电线路出现故障的；（4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。尽管有前款约定，农村综合产权交易服务平台将采取合理行动积极促使服务恢复正常。</strong></p>
				
				<p><strong>2、本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于农村综合产权交易服务平台无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，农村综合产权交易服务平台不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，农村综合产权交易服务平台亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，农村综合产权交易服务平台不承担任何责任。</strong></p>
				
				<p><strong>3、用户同意，对于农村综合产权交易服务平台公司向用户提供的下列产品或服务的质量缺陷本身及其引发的任何损失（如有），农村综合产权交易服务平台公司无需承担任何责任:（1）农村综合产权交易服务平台公司向用户免费提供的各项服务。（2）农村综合产权交易服务平台公司向用户赠送的任何商品或服务。</strong></p>
				
				<p><strong>七、协议的变更与终止</strong></p>
				
				<p>您同意农村综合产权交易服务平台得基于其自行之考虑，因任何理由，包含但不限于长时间（超过一年）未使用，或农村综合产权交易服务平台认为您已经违反本服务协议的文字及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。您同意依本服务协议任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，农村综合产权交易服务平台可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及/或禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，农村综合产权交易服务平台对您或任何第三人均不承担任何责任，且保留按照本协议的约定向你追究违约责任的权利</p>
				
				<p>八、法律的使用与争议的解决</p>
				
				<p>1、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律法规。</p>
				
				<p>2、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向农村综合产权交易服务平台所在地长沙市天心区人民法院提起诉讼，但按照中华人民共和国法律，因级别管辖的规定的，双方均可向长沙市天心区人民法院的上一级人民法院申请诉讼解决。</p>
				
				<p>九、通知</p>
				
				<p>1、本协议项下农村综合产权交易服务平台对用户的所有通知均可通过网页公告、软件内公告、电子邮件、手机短信或常规信件传送等方式进行；除常规的信件传送以邮戳载明签收日期为送达日外，其他该等通知均于发送之日视为已送达收件人。</p>
				
				<p>2、用户对农村综合产权交易服务平台的通知均应通过农村综合产权交易服务平台公司对外正式公布的通信地址或电子邮件地址等联系信息进行送达。</p>
				
				<p>十、其他</p>
				
				<p>1、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
				
				<p>2、本协议构成各方之间就本协议内容所达成的完全合意；本协议签署前存在的、或与本协议相矛盾或抵触的一切合意，无论其为口头、书面或其他形式均归于无效。</p>
				
				<p>3、农村综合产权交易服务平台未行使或执行本服务协议任何权利或规定，不构成对前述权利或权益之放弃。</p>
			</div>
			<template v-slot:modal-footer="{ ok }">
				<b-button variant="success" class="width-200" @click="ok()">
					我知道了
				</b-button>
			</template>
		</b-modal>
		<land-modal ref="landmodal" :classId="gyPara[0].classId" @change="onLandChange"></land-modal>
		<map-footer></map-footer>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import city from "@/components/city/city.vue";
	import datePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/zh-cn';
	import landModal from "./components/landmodal.vue";
	export default {
		components: {
			city,datePicker,landModal
		},
		data() {
			return {
				isReadMore:false,
				isAgreementShow: false,
				isMsShow: false,
				isMapShow: false,
				isAreaShow: false,
				tag: [],
				fileList: [[],[],[]],
				metreArray: ['商业及住宅','公共用地', '农房'],
				currentUtil: {
					price: '万元',
					acreage: '亩',
					unitPrice: '元/亩/年',
				},
				isRead: 'false',
				areaValue: '',
				mapKeyword: '',
				ag: {
					lat: 0,
					lng: 0
				},
				gyPara: [{
					//农用地
					classId: '', //用途大类
					acreageM: '', //实测面积
					isBaseFarmland: '', //是否基本农田
					landUse: '', //用途小类
					code: '', //地块编码
					dz: '', //东至
					xz: '', //西至
					nz: '', //南至
					bz: '', //北至

					//林地
					contractType: '', //承包性质
					forestType: '', //林种
					treeType: '',//树种
					treeYears: '', //树龄

					//农房
					decorationDegree: '', //装修程度
					finishDate: '', //竣工日期
					landAcreage: '', //宗地面积
					oneForOne: '', //一宅一户
					realEstateAcreage: '', //建筑面积
					realEstateAddress: '', //不动产地址
					structure: '', //结构
					totalPlies: '', //总层数
				}],
				para: {
					classId: '',
					priceUnit: '', //价格单位
					acreageUnit: '', //面积单位
					area: '', //地区
					address: '', //详细地址
					location: '', //坐标
					measureArea: '', //土地面积
					flowType: '', //流转类型
					price: '', //金额
					years: '', //使用年限
					title: '', //标题
					content: '', //土地详情
					contacts: '', //联系人
					contactNumber: "", //联系方式
					unitPrice: '',//单价
					unitPriceUnit: '', //单价单位
					userNeedsAgriculturalLands: [],
					userNeedsFiles: [],
					userNeedsFarms: [],
					userNeedsForestLands:[],
					userNeedsOtherLands:[],
          			serviceTag:[],//需求
				},
				useLandList: [],
				allMeasureArea: 0,
        		tags:[],
			}
		},
		computed: {
			 ...mapGetters(["dict","user"]),
			getChildUseLandList() {
				if(this.dict.landUseType.length > 0) {
					let c = this.dict.landUseType.find(x=> x.id == this.gyPara[0].classId);
					if (c)  {
						return c.children;
					} 
				}
				return []
			},
			getUseLand() {
				if(this.dict.landUseType.length > 0) {
					let arr = this.dict.landUseType.filter(x=> x.text != '工矿仓储' && x.text != '商业及住宅' && x.text != '公共用地' && x.text != '未利用地' && x.text != '水域');
					if(this.gyPara[0].classId == '') {
						this.gyPara[0].classId = arr[0].id;
					}
					return arr;
				}
				return [];
			},
			getcontractTypetValue () {
				return function(id) {
				if(this.dict && this.dict.forestcontractortype) {
					let m = this.dict.forestcontractortype.find(x => x.id == id)
					if(m) {
					return m.text;
					}
				}
				return ''
				}
			},
			getdecorationDegreeTypetValue () {
				return function(id) {
				if(this.dict && this.dict.decorationDegree) {
					let m = this.dict.decorationDegree.find(x => x.id == id)
					if(m) {
					return m.text;
					}
				}
				return ''
				}
			},
			getstructureTypetValue () {
				return function(id) {
				if(this.dict && this.dict.structure) {
					let m = this.dict.structure.find(x => x.id == id)
					if(m) {
					return m.text;
					}
				}
				return ''
				}
			},
			DateFormat() {
				return function(date) {
					var date = new Date(date);
					date.toLocaleDateString()
					var y = date.getFullYear()
					var m = date.getMonth() + 1
					m = m < 10 ? ('0' + m) : m
					var d = date.getDate()
					d = d < 10 ? ('0' + d) : d
					const time = y + '-' + m + '-' + d
					return time
				}
			},
			getUseLandValue()	{
				return function(code) {
					let value = '';
					if(this.dict && this.dict.landUseType) {
						let lp = null, lc = null;
                        lp = this.dict.landUseType.find(x => x.children.find(y=> y.id  == code));
                        if(lp) {
                            value = lp.text;
                            lc = lp.children.find(x=> x.id == code);
                            if(lc) {
                               value = value + '/' + lc.text;
                            }
                        }
                    }
					return value
				}
			}
		},
        methods: {
			onChooseTags(value) {
				if(this.para.serviceTag.includes(value)) {
					this.para.serviceTag.splice(this.para.serviceTag.findIndex(x => x === value), 1);
				} else {
					this.para.serviceTag.push(value);
				}
				console.log(this.para.serviceTag)
			},
			maChange() {
				this.gyPara[0].acreageM = this.para.measureArea;
				this.allMeasureArea = this.gyPara.map(x=> { return x.acreageM} ).reduce((n,m)=> parseInt(n)+parseInt(m));
				this.tag[1] = this.allMeasureArea + this.currentUtil.acreage;
				this.autoFillTitle();
			},
			laChange() {
				this.para.measureArea = this.gyPara[0].landAcreage;
				this.allMeasureArea = this.gyPara.map(x=> { return x.landAcreage} ).reduce((n,m)=> parseInt(n)+parseInt(m));
				this.tag[1] = this.allMeasureArea + this.currentUtil.acreage;
				this.autoFillTitle();
			},
			onClassClick(m) {
				this.gyPara[0].classId = m.id;
				if(this.metreArray.includes(m.text)) {
					this.currentUtil.acreage = '平方米';
				} else {
					this.currentUtil.acreage = '亩';
				}
			},
			onUlClick(m) {
				this.para.classId = m.classId;
				this.gyPara[0].landUse = m.id;
				this.tag[2] = m.text;
				this.autoFillTitle();
			},
			onFtClick(m) {
				this.para.flowType = m.id;
				this.tag[3] = m.text;
				this.autoFillTitle();
			},
			autoFillTitle() {
				this.para.title = this.tag.join(' ');
			},
			onChooseArea(a) {
				this.areaValue = a.province.name + (a.city.name == '市辖区' ? '' : a.city.name ) + a.area.name;
				this.tag[0] = (a.city.name == '市辖区' ? a.province.name : a.city.name ) + a.area.name;
				this.para.area = a.area.code;
				this.mapKeyword =this.areaValue;
				this.isAreaShow = false;
				this.autoFillTitle();
			},
			onMapClick(e) {
				this.ag = e.Ag;
				this.para.location = e.Ag.lat + ',' + e.Ag.lng;
			},
			onMapLocation() {
			  
				if(this.para.area == '' && this.para.address == '') {
					this.$model.toast('请先选择所在地区',2000);
				} else {
					this.mapKeyword = this.areaValue + this.para.address;
					this.isMapShow = true;
				}
			},
			afterReadLand(file) {
				this.$http.upload(this.$api.personal.upload + '/1' , 'userNeedsFiles', file).then((res) => {
					if(res.code == 1) {
						this.para.userNeedsFiles.push(...res.result);
					}
				});
			},
			beforeDeteleLand(e) {
				let index = this.para.userNeedsFiles.findIndex(item => x=> x.originalFilename == e.file.name && x.uploadType == 1);
				if(index >= 0) {
					this.para.userNeedsFiles.splice(index, 1)
				}
				return true;
			},
			afterReadVideo(file) {
				this.$http.upload(this.$api.personal.upload + '/2' , 'userNeedsFiles', file).then((res) => {
					if(res.code == 1) {
						this.para.userNeedsFiles.push(res.result[0]);
					}
				});
			},
			beforeDeteleVideo(e) {
				let index = this.para.userNeedsFiles.findIndex(item => x=> x.originalFilename == e.file.name && x.uploadType == 2);
				if(index >= 0) {
					this.para.userNeedsFiles.splice(index, 1)
				}
				return true;
			},


			afterReadWarrant(file) {
				this.$http.upload(this.$api.personal.upload + '/3' , 'userNeedsFiles', file).then((res) => {
					if(res.code == 1) {
						this.para.userNeedsFiles.push(res.result[0]);
					}
				});
			},
			beforeDeteleWarrant(e) {
				let index = this.para.userNeedsFiles.findIndex(item => x=> x.originalFilename == e.file.name && x.uploadType == 3);
				if(index >= 0) {
					this.para.userNeedsFiles.splice(index, 1)
				}
				return true;
			},
			onEdit(i,m) {
				this.$refs.landmodal.showModal(i,m);
			},
			onDelete(i) {
				if(this.gyPara.length > 1) {
					this.gyPara.splice(i,1);
				}
			},
			onLandChange(index,m) {
				if(index > 0) {
					this.$set(this.gyPara,index,m);
				} else {
					this.gyPara.push(m);
				}
				if(this.getUseLandValue(this.gyPara[0].landUse).includes('农房')) {
					this.allMeasureArea = this.gyPara.map(x=> { return x.landAcreage || 0} ).reduce((n,m)=> parseInt(n)+parseInt(m));
				} else {
					this.allMeasureArea = this.gyPara.map(x=> { return x.acreageM || 0 } ).reduce((n,m)=> parseInt(n)+parseInt(m));
				}
				this.tag[1] = this.allMeasureArea + this.currentUtil.acreage;
				this.autoFillTitle();
			},
			verify() {
				if( this.gyPara[0].landUse == '' || this.para.area == '' || this.para.measureArea.trim() == '' || this.para.flowType == '' || this.para.price.trim() == '' || this.para.years.trim() == '' || this.para.title.trim() == '' || this.para.contacts.trim() == '' || this.para.contactNumber.trim() == ''  < 0 || this.para.userNeedsFiles.findIndex(x=>x.uploadType == 1) < 0) {
					this.$model.toast('带*符号为必填项，请填写必填信息才能发布！',2000)
					return false;
				} else {
					if (!this.$test.mobile(this.para.contactNumber)) {
						this.$model.toast('请输入正确的联系电话',2000)
						return false
					}
				}
				if(this.isRead == 'false') {
					this.$model.toast('请阅读并同意土地流转协议',2000)
					return false;
				}
				let p = this.dict.priceUnit.find(x=>x.text == this.currentUtil.price);
				let u = this.dict.priceUnit.find(x=>x.text == this.currentUtil.unitPrice);
				let a = this.dict.acreageUnit.find(x=>x.text == this.currentUtil.acreage);
				if(p) {
					this.para.priceUnit = p.id
				}
				if(a) {
				 	this.para.acreageUnit =	a.id;
				}
				if(u) {
				 	this.para.unitPriceUnit = u.id;
				}
				if (this.para.serviceTag){
					this.para.serviceTag=this.para.serviceTag.join(',');
				}
				this.gyPara.forEach(x => {
					let m = this.dict.landUseType.find(y => y.id == x.classId);
					let model = null;
					if(m) {
						switch (m.text) {
							case '耕地':
							case '园地':
							case '草地':
								model = {
									acreageM: x.acreageM, //面积
									isBaseFarmland: x.isBaseFarmland, //是否基本农田
									landUse: x.landUse, //土地用途小类
									code:x.code,
									bz: x.bz, //北至
									dz: x.dz,//东至
									nz: x.nz,//南至
									xz: x.xz,//西至
								}
								if(model.code == '') {
									this.$delete(model,'code');
								}
								this.para.userNeedsAgriculturalLands.push(model);
								break;
							case '林地':
								model = {
									acreageM: x.acreageM, //面积
									code:x.code,
									contractType: x.contractType, //承包性质
									forestType: x.forestType, //林种
									treeType: x.treeType,//树种
									treeYears: x.treeYears, //树龄
									landUse: x.landUse, //土地用途小类
									bz: x.bz, //北至
									dz: x.dz,//东至
									nz: x.nz,//南至
									xz: x.xz,//西至
								}
								if(model.code == '') {
									this.$delete(model,'code');
								}
								this.para.userNeedsForestLands.push(model);
								break;
							case '其他土地':
								model = {
									acreageM: x.acreageM, //面积
									code:x.code,
									landUse: x.landUse, //土地用途小类
									bz: x.bz, //北至
									dz: x.dz,//东至
									nz: x.nz,//南至
									xz: x.xz,//西至
								}
								if(model.code == '') {
									this.$delete(model,'code');
								}
								this.para.userNeedsOtherLands.push(model);
								break;
							case '农房':
								model = {
									landUse: x.landUse, //土地用途小类
									code:x.code,
									bz: x.bz, //北至
									dz: x.dz,//东至
									nz: x.nz,//南至
									xz: x.xz,//西至
									decorationDegree: x.decorationDegree, //装修程度
									finishDate: x.finishDate, //竣工日期
									landAcreage: x.landAcreage, //宗地面积
									oneForOne: x.oneForOne, //一宅一户
									realEstateAcreage: x.realEstateAcreage, //建筑面积
									realEstateAddress: x.realEstateAddress, //不动产地址
									structure: x.structure, //结构
									totalPlies: x.totalPlies, //总层数
								}
								if(model.code == '') {
									this.$delete(model,'code');
								}
								this.para.userNeedsFarms.push(model);
								break;
						}
					}
				});
				
				return true;
			},
			addLand() {
				if(this.verify()) {
					this.para.measureArea = this.allMeasureArea; //总面积
					this.$http.post(this.$api.user.addLand,this.para).then(res => {
						if(res.code  == 1) {
							this.$model.toast('发布成功，请等待平台审核',2000);
							setTimeout(() => {
								this.$router.go(0);
							}, 2000);
						} else {
							this.$model.toast(res.reason,2000);
						}
					}).catch(error => {
						this.$model.toast(error.reason,2000);
					})
				}
            }
        },
		created() {
			if (this.$route.query.id > 0) {
				 this.$http
				.post(this.$api.supply.getLandDetail + "/" + this.$route.query.id)
				.then((res) => {
					
				});
			}
		}
	}
</script>

<style>
	@import url('~@/assets/css/city.css');
	.screen .active {
		background: #00cc00;
		color: #FFFFFF;
		border-radius: 6px;
	}

	.map {
		margin: 15px 116px;
		width: 800px;
		height: 300px;
	}

	.show-more {
		height: 38px; 
		overflow: hidden;
	}
</style>
